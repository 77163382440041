/* General styling for the body */


/* Align the services section to the center-left */
.services-section {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    text-align: left;
}

/* Paragraph styling */
.services-section p {
    margin: 20px 0;
    font-size: 1em;
    color: #333;
}

/* Heading styles */
.services-section h3, .services-section h4 {
    color: #094C09;
    margin-top: 20px;
    font-size: 1.2em;
}

/* List styling */
.services-section ul {
    margin: 10px 0 10px 20px;
    padding: 0;
    font-size: 1em;
    color: #333;
}

.services-section li {
    margin-bottom: 8px;
}
