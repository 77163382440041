.research4good-section {
    text-align: center;
    padding: 2rem;
}

.research4good-section h2 {
    font-size: 1.9rem;
    margin-bottom: 2rem;
    color: #094C09;
}

.sponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center align horizontally */
    margin: 0 auto 1.9rem auto; /* Center the section horizontally */
}

.beneficiary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1.9rem;
    width: 100%;
}

.sponsor-image {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    margin-bottom: 1rem;
    object-fit: cover;
}

.sponsor-details, .beneficiary-details {
    text-align: center;
}

.sponsor-details h3, .beneficiary-details h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.sponsor-details p, .beneficiary-details p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

/* Responsive Design */
@media (min-width: 768px) {
    .sponsor, .beneficiary {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .sponsor-image, .beneficiary-image {
        margin-right: 2rem;
    }

    .sponsor-details, .beneficiary-details {
        text-align: center;
    }
}
