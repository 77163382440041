/* src/components/Header.css */

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    padding: 1rem;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    max-width: 1000px;
  }
  
  .logo-link {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 50px;
  }
  
  .news-bar {
    width: 100%;
    background-color: #444;
    color: white;
    padding: 0.5rem;
    text-align: center;
  }
  
  .news-bar p {
    margin: 0;
    font-size: 1rem;
  }
  
  .news-bar a {
    color: white;
    text-decoration: none;
  }
  
  .news-bar a:hover {
    text-decoration: underline;
  }
  
  @media (min-width: 768px) {
    .header {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .header-content {
      flex-direction: row;
    }
  
    .news-bar p {
      font-size: 1.2rem;
    }
  }
  