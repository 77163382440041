/* CompletedProjects.css */
.completed-projects-section {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    padding: 2rem;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
}

.completed-projects-section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #094C09;
    text-align: center;
}

.completed-projects-section p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #000000;
}

.completed-projects-section ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 1rem;
}

.completed-projects-section ul ul {
    list-style-type: circle;
    padding-left: 2rem;
}

.completed-projects-section ul li {
    margin-bottom: 0.5rem;
    color: #000000;
}

.completed-projects-section a {
    color: #0000EE;
    text-decoration: underline;
}
