/* about.css */

/* General container styling */
.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
  }
  
  /* Content styling */
  .about-content {
    display: flex;
    align-items: center;
    text-align: left; /* Ensure text is left-aligned */
    max-width: 1000px; /* Limit the maximum width of the content */
    margin: 0 20px;
    /* Add some horizontal margin for spacing */
  }
  
  /* Image styling */
  .about-image {
    width: 550px; /* Set a fixed width for the image */
    height: auto; /* Maintain the aspect ratio */
    margin-right: 20px; /* Add some space between the image and the text */
  }
  
  /* Paragraph styling */
  .about-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 525;
    font-size: 1em;
    line-height: 1.6;
    color: #333; /* Dark grey text color */
  }
  