src/components/Navbar.css

.navbar {
    
    flex-direction: column;
    
    
  }
  
  .navbar-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-toggler {
    background: none;
    border: none;
    color: #094c09;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .navbar-menu {
    display: none;
    flex-direction: column;
  }
  
  .navbar-menu.is-active {
    display: flex;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
    padding: 0.5rem 0;
  }
  
  /* .nav-link:hover {
    background-color: #575757;
  } */
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-toggle {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #444;
    padding: 0.2rem;
    top: 100%;
    left: 0;
    width: 150px;
  }
  
  .dropdown-menu .dropdown-item {
    color: #094c09;
    text-decoration: none;
   /* padding: 0.5rem 0;*/
  }
  
  .dropdown-menu .dropdown-item:hover {
    background-color:#094c09;
  }
  
  @media (min-width: 768px) {
    .navbar {
      flex-direction: row;
      align-items: center;
    }
  
    .navbar-toggler {
      display: none;
    }
  
    .navbar-menu {
      display: flex;
      flex-direction: row;
    }
  
    .nav-link {
      padding: 0.5rem 1rem;
    }
  
    .dropdown-menu {
      position: static;
      display: block;
      background: none;
      padding: 0;
      width: auto;
    }
  
    .dropdown-item {
      padding: 0;
    }
  }
  