/* Importing Open Sans font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
  color: #094C09; /* Change text color */
  margin: 0;
  padding: 0;
  background-color: #fff;
}

h1, h2 {
  color: #094C09; /* Change text color */
  font-weight: 600;
  margin-bottom: 15px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #094C09; /* Change text color */
}

ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #094C09; /* Change text color */
}

ul li {
  margin-bottom: 10px;
}

.App {
  text-align: center;
}

.header {
  display: flex; /* Use flex display */
  align-items: center; /* Center items vertically */
  background-color: #fff;
  padding: 10px 40px; /* Add padding to the header */
  box-shadow: 0 4px 2px -2px gray;
  flex-direction: row;
}

.logo {
  width: 250px; /* Increase logo size */
  height: 4em;
  display: flex;
  align-items: center;
  
}

.navbar {
  display: flex;
  justify-content: flex-end; /* Align the navigation links to the left */
  align-items: center; /* Center the navigation links vertically */
  margin-left: 300px; /* Push the navbar to the right side */
  flex-grow: 1;
}

.nav-link {
  margin: 0 20px; /* Increase spacing between menu items */
  text-decoration: none;
  color: #094C09; /* Change text color */
  font-weight: bold;
  font-size: 1.2rem; /* Increase font size */
  cursor: pointer;
}
.nav-link:hover {
  color: blue;
}

.nav-link:active {
  color: blue;
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 0;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #094C09; /* Change text color */
  font-weight: bold;
  cursor: pointer;
  font-size: 1.2rem; /* Increase font size */
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-item {
  padding: 10px 15px;
  text-decoration: none;
  color: #094C09; /* Change text color */
  display: block;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  color: blue;
}

.main-content {
  padding: 50px 20px; /* Adjust padding as needed */
}

.content-wrapper {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: center; /* Center align content horizontally */
  gap: 20px;
}

.text-content {
  flex: 0.5;
  padding-right: 20px; /* Space between text and image */
  text-align: center; /* Align text to the center */
}

.main-image {
  display: flex;
  width: 40%; /* Reduce image size */
  height: auto;
  margin-left: 0px; /* Add some margin to the left */
}

.footer {
  background-color: #f9f9f9;
  padding: 20px 40px; /* Add padding to left and right */
}

.partners {
  width: flex;
  height: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px;
}

.partners img {
  width: 175px; /* Adjust as needed */
}

/* News bar styling */
.news-bar {
  background-color: #094C09; /* Matching the green theme */
  color: white;
  padding: 10px 0;
  font-size: 0.9em;
  font-family: Open Sans, sans-serif;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 2px -2px gray; /* Add shadow for separation */
}

.news-bar p {
  display: inline-block;
  margin: 0;
  padding-left: 100%;
  animation: scroll 13s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.news-bar a {
  color: #ffd700; /* Gold color for anchor links */
  text-decoration: none;
  margin: 0 5px;
}

.news-bar a:hover {
  text-decoration: underline;
}
