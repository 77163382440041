/* contactus.css */

/* General styling for the body */
body {
    font-family: Open Sans, sans-serif;
    line-height: 1.4;
    margin: 20px;
    padding: 20px;
  }
  
  /* Align all text to the left */
  .contact-us-section {
    
    display: wrap;
    text-align: left;
    margin-left: 350px;
    margin-right: 350px;
  }
  
  /* Heading styles */
  h2 {
    text-align: center;
    font-size: 1.8em;
    font-weight: 600;
    color: #094C09;
    margin-top: 20px;
  }
  
  /* List styling */
  ul {
    margin: 10px 0 10px 20px;
    padding: 0;
    font-size: 1.0em;
    color:#333;
  }
  
  li {
    margin-bottom: 8px; 
  }
  
  /* Link styling */
  a {
    color: #333;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }